import React from "react"

import styled from "styled-components"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import colors from "../utils/colors"
import { Container } from "../utils/common-styles"
import ogImage from "../images/image_softwareentwicklerfinden.png"

const PostTitle = styled.h3`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${colors.DARK_BLUE};
`

const Policy = ({ path }) => (
  <LayoutPage
    path={path}
    title="Datenschutzrichtlinie"
    subtitle="Verarbeitung personenbezogener Daten auf Softwareentwicklerfinden.de"
  >
    <SEO title="Datenschutzrichtlinie" description="Privacy policy" image={ogImage} />
    <Container>
      <p>
        Für Softwareentwicklerfinden.de ist persönliche Integrität wichtig. Wir bemühen uns um ein hohes
        Datenschutzniveau. In dieser Richtlinie erklären wir, wie wir personenbezogene Daten
        erfassen und verwenden. Zudem legen wir dar, welche Rechte Sie haben und wie Sie diese
        durchsetzen können.
      </p>
      <p>
        Sie können uns jederzeit gern kontaktieren, wenn Sie Fragen zur Verarbeitung Ihrer
        personenbezogenen Daten durch uns haben. Die Kontaktdaten befinden sich am Ende dieses
        Textes.
      </p>
      <h4>
        {" "}
        Was sind personenbezogene Daten und worin besteht die Verarbeitung personenbezogener Daten?
      </h4>
      <p>
        Personenbezogene Daten sind alle Daten über eine lebende natürliche Person, die direkt oder
        indirekt mit dieser Person verknüpft werden können. Es handelt sich dabei nicht nur um Namen
        und Sozialversicherungsnummern, sondern beispielsweise auch um Fotos und E-Mail-Adressen.
      </p>
      <p>
        Als Verarbeitung personenbezogener Daten gilt alles, was mit den personenbezogenen Daten in
        den IT-Systemen geschieht, unabhängig davon, ob es sich um Mobilgeräte oder Computer
        handelt. Dazu gehören Erfassung, Registrierung, Strukturierung, Speicherung, Verarbeitung
        und Übertragung. In einigen Fällen können auch manuell geführte Verzeichnisse dazugehören.
      </p>
      <h4>Manager für personenbezogene Daten</h4>
      <p>
        Für die Verarbeitungen, die innerhalb des Betriebs von Softwareentwicklerfinden.de erfolgen, ist
        Softwareentwicklerfinden.de für personenbezogene Daten verantwortlich.
      </p>
      <h4>Welche personenbezogenen Daten erfassen wir über Sie und warum?</h4>
      <p>Wir verarbeiten hauptsächlich Ihren Namen und Ihre Kontaktdaten.</p>
      <h4>Assistenten für personenbezogene Daten</h4>
      <p>
        In einigen Situationen ist es erforderlich, dass wir andere Parteien engagieren. Diese sind
        dann unsere Assistenten für personenbezogene Daten. Wir prüfen Assistenten für
        personenbezogene Daten, um sicherzustellen, dass sie die Sicherheit und Vertraulichkeit
        personenbezogener Daten garantieren. Werden Assistenten für personenbezogene Daten
        engagiert, so geschieht dies nur zu Zwecken, die mit unseren eigenen Verarbeitungszwecken
        vereinbar sind.
      </p>
      <h4>Akteure, die unabhängig für personenbezogene Daten verantwortlich sind</h4>
      <p>
        Wir geben Ihre personenbezogenen Daten auch an bestimmte andere Akteure weiter, die
        unabhängig für personenbezogene Daten verantwortlich sind, darunter Stellen wie die
        schwedische Steuerbehörde, wenn wir dazu verpflichtet sind, solche Daten aufgrund von
        Gesetzen oder Regierungsentscheidungen offenzulegen. Werden Ihre personenbezogenen Daten an
        einen Akteur weitergegeben, der unabhängig für personenbezogene Daten verantwortlich ist, so
        gelten die Datenschutzrichtlinie und die Handhabung personenbezogener Daten der jeweiligen
        Organisation.
      </p>
      <h4>Wie lange speichern wir Ihre personenbezogenen Daten?</h4>
      <p>
        Wir speichern Ihre personenbezogenen Daten nie länger, als es für den jeweiligen Zweck
        erforderlich ist. Aufgrund geltender Gesetze müssen bestimmte Daten in den Konten
        beispielsweise für mindestens sieben Jahre gespeichert werden.
      </p>
      <h4>Was sind Ihre Rechte als registrierte Person?</h4>
      <p>
        Als registrierte Person haben Sie nach den aktuellen Gesetzen eine Reihe von Rechten. Sie
        haben das Recht, einen Auszug zu erhalten, aus dem hervorgeht, welche personenbezogenen
        Daten wir über Sie erfasst haben. Sie können die Berichtigung falscher Informationen und in
        einigen Fällen eine Löschung beantragen.
      </p>
      <h4>Kontaktieren Sie uns, wenn Sie Fragen zur Verarbeitung personenbezogener Daten durch uns
        haben.</h4>
      <p>
        Wenn Sie Fragen zur Verarbeitung personenbezogener Daten durch uns haben, nehmen{" "}
        <a href="/contact/">Sie Kontakt</a> mit uns auf.
      </p>
      <p>
        Wir können Änderungen an unserer Datenschutzrichtlinie vornehmen. Die aktuelle Fassung der
        Datenschutzrichtlinie ist jederzeit hier auf der Website verfügbar.
      </p>{" "}
    </Container>
  </LayoutPage>
)

export default Policy
